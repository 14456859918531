import React, { Suspense, memo, useEffect, useRef, useState } from 'react'

import Layout from '../../../components/Layout'

import './style.css'

import { getUrlParams } from '../../../utils/helpers/common'
import { trackEvent } from '../../../analytics'

import { EVENTS } from '../../../analytics/events'
import OurProgram from '../../../components/OurProgram'
// import EducationalContent from '../../components/EducationalContent'
// import WellBeing from '../../components/WellBeing'
// import HealthRecord from '../../components/HealthRecord'
// import HeartFeltStories from '../../components/HeartFeltStories'
// import MeetCareNavigator from '../../components/MeetCareNavigator'
// import OncoRecommended from '../../components/OncoRecommended'
// import CareCoins from '../../components/CareCoins'
// import StartJourney from '../../components/StartJourney'
// import Faq from '../../components/Faq'
import BookLabImage from '../../../assets/images/book-lab.png'
import ConsultPsychologistImage from '../../../assets/images/consult-psychologist.png'
import ConsultNutritionstImage from '../../../assets/images/consult-nutrinist.png'
import CareProductImage from '../../../assets/images/care-product.png'
// import Slider from '../../components/Slider'
// import RequestCallback from '../../components/RequestCallback'
import Welcome from '../../../components/Welcome'
import ZydusImage from '../../../assets/images/zydus.png'
import moveToStore from '../../../utils/helpers/deviceType'
import { useLocation } from 'react-router-dom'
import WelcomeImage from '../../../assets/images/welcome.png'
import constants from '../../../constants'

const EducationalContent = React.lazy(
  () => import('../../../components/EducationalContent'),
)
const WellBeing = React.lazy(() => import('../../../components/WellBeing'))
const HealthRecord = React.lazy(
  () => import('../../../components/HealthRecord'),
)
const HeartFeltStories = React.lazy(
  () => import('../../../components/HeartFeltStories'),
)

const OncoRecommended = React.lazy(
  () => import('../../../components/OncoRecommended'),
)

const CareCoins = React.lazy(() => import('../../../components/CareCoins'))
const StartJourney = React.lazy(
  () => import('../../../components/StartJourney'),
)
const Faq = React.lazy(() => import('../../../components/Faq'))
const RequestCallback = React.lazy(
  () => import('../../../components/RequestCallback'),
)
const Slider = React.lazy(() => import('../../../components/Slider'))

const MeetCareNavigator = React.lazy(
  () => import('../../../components/MeetCareNavigator'),
)

// const BookLabImage = React.lazy(
//   () => import('../../../assets/images/book-lab.png'),
// )

// const ConsultPsychologistImage = React.lazy(
//   () => import('../../../assets/images/consult-psychologist.png'),
// )

// const ConsultNutritionstImage = React.lazy(
//   () => import('../../../assets/images/consult-nutrinist.png'),
// )

// const CareProductImage = React.lazy(
//   () => import('../../../assets/images/care-product.png'),
// )

const AnayaCarePage = () => {
  const [urlParams, setUrlParams] = useState(null)
  const innerRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(document.body.scrollTop)
  const [addbottom, setBottom] = useState()
  useEffect(() => {
    const urlParams = getUrlParams()
    setUrlParams(urlParams)
    trackEvent(EVENTS.PAGE_VIEW, {
      screenName: 'Programs Screen',
      pageName: 'Anaya care',
      ...urlParams,
    })
  }, [])

  useEffect(() => {
    const div = innerRef.current
    // subscribe event
    div.addEventListener('scroll', handleOnScroll)
    return () => {
      // unsubscribe event
      div.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  const handleOnScroll = (e) => {
    // NOTE: This is for the sake of demonstration purpose only.
    // Doing this will greatly affect performance.
    setScrollTop(e.target.scrollTop)
    e.target.scrollTop >= 625 ? setBottom(true) : setBottom(false)
  }

  return (
    <Layout>
      <div
        className="container h-[100vh]"
        style={{ padding: 0, overflow: 'auto' }}
        ref={innerRef}
      >
        <div className="anaya-page-container">
          <div className="content min-w-sm max-w-lg">
            <Welcome
              welcomeImage={WelcomeImage}
              storeUrl={'https://mytatva.page.link/SIGRIMA'}
              careProgramType={constants.CARE_PROGRAM_TYPE.ANAYA}
            ></Welcome>
            <OurProgram
              careProgramType={constants.CARE_PROGRAM_TYPE.ANAYA}
            ></OurProgram>
            <Suspense fallback={<div>Loading...</div>}>
              <EducationalContent></EducationalContent>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <WellBeing></WellBeing>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <HealthRecord
                isBylypsa={false}
                careProgramType={constants.CARE_PROGRAM_TYPE.ANAYA}
              ></HealthRecord>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <HeartFeltStories
                careProgramType={constants.CARE_PROGRAM_TYPE.ANAYA}
              ></HeartFeltStories>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <MeetCareNavigator></MeetCareNavigator>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <OncoRecommended></OncoRecommended>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <CareCoins
                careProgramType={constants.CARE_PROGRAM_TYPE.ANAYA}
              ></CareCoins>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Slider
                data={[
                  CareProductImage,
                  ConsultNutritionstImage,
                  ConsultPsychologistImage,
                  BookLabImage,
                ]}
                renderArrowPrevFn={(clickHandler, hasPrev, labelPrev) =>
                  hasPrev && <></>
                }
                renderArrowNextFn={(clickHandler, hasNext, labelNext) =>
                  hasNext && <></>
                }
              ></Slider>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <StartJourney
                careProgramType={constants.CARE_PROGRAM_TYPE.ANAYA}
              ></StartJourney>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Faq></Faq>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <RequestCallback></RequestCallback>
            </Suspense>
            {/* <div className="">
              <div className="flex flex-col  mb-8 w-[100%]">
                <button className="bg-[#F15223] text-white p-3 border-1 rounded-lg">
                  Download MyTatva App
                </button>
              </div>
            </div> */}
            {/* {urlParams?.plan_master_id &&
            urlParams?.plan_package_duration_rel_id &&
            urlParams?.utm_source ? (
              <Form />
            ) : (
              <p className="text-red-800">Invalid url or link Expired</p>
            )} */}
            <div className="text-center pt-[30px] pb-[200px] font-[300] text-[12px] leading-[26px]">
              Copyright © 2024 TatvaCare | All Rights Reserved
              <div className="flex justify-center items-center">
                <span>Powered by &nbsp;&nbsp;</span>
                <span>
                  <img src={ZydusImage} />
                </span>
              </div>
            </div>
          </div>
          <div
            className={`w-full text-center fixed z-15 left-0 bg-white bottom-gray-class ${
              addbottom ? 'bottom-0' : 'bottom-[-90px]'
            }`}
            style={{
              padding: '15px 0',
            }}
          >
            <div className="">
              <button
                onClick={() => moveToStore('https://mytatva.page.link/SIGRIMA')}
                className="bg-[#F15223] text-[18px] font-[600] text-white p-3 border-1 p w-[358px] border-1 rounded-[16px] p-[15px]"
              >
                Download MyTatva App
              </button>
            </div>
            {/* <button
              className="bg-[#F15223] text-[18px] font-[600] text-white p-3 border-1 rounded-lg w-[358px] border-1 rounded-[16px] p-[15px]"
              onClick={() => moveToStore()}
            >
              Download MyTatva App
            </button> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(AnayaCarePage)
