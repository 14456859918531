import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Homepage from './Homepage'
import Register from './Register'
import AnayaCarePage from './Programs/AnayaCare'
import BilypsaCarePage from './Programs/BilypsaCare'
import InvalidPage from './InvalidPage'

import '../styles/main.css'
import { ThemeProvider, createTheme } from '@mui/material'

const AppRoutes = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#8C2FDF',
      },
      secondary: {
        main: '#54595F',
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/register" Component={Register} />
          <Route path="/programs/anaya-care" Component={AnayaCarePage} />
          <Route path="/programs/bilypsa-care" Component={BilypsaCarePage} />
          <Route path="/programs/akumentis-care" Component={BilypsaCarePage} />
          <Route path="/" Component={InvalidPage} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default AppRoutes
