import React from 'react'
import ExpertiseImage from '../../assets/images/expertise.png'
import TrustedImage from '../../assets/images/trusted.png'
// import WelcomeImage from '../../assets/images/welcome.png'
// import WelcomeBilypsaImage from '../../assets/images/welcome-bilypsa.png'
// import MyTatvaJourney from '../Test'
import { useLottie } from 'lottie-react'
import moveToStore from '../../utils/helpers/deviceType'
import { useLocation } from 'react-router-dom'
import constants from '../../constants'

const Welcome = ({ careProgramType, welcomeImage, storeUrl }) => {
  return (
    <div className="flex flex-col relative">
      <img
        src={welcomeImage}
        width="full"
        height="full"
        className=""
        style={{ position: 'relative', zIndex: 2 }}
      />
      <div className="absolute top-[535px] w-full z-10 text-center">
        <button
          onClick={() => moveToStore(storeUrl)}
          className="bg-[#F15223] text-[18px] font-[600] text-white p-3 border-1 p w-[358px] border-1 rounded-[16px] p-[15px]"
        >
          Download MyTatva App
        </button>
      </div>
      {careProgramType === constants.CARE_PROGRAM_TYPE.ANAYA && (
        <div
          className="bg-[#875ECB] z-5 mt-[-28px] flex flex-col pb-[40px] pt-[100px] px-[40px] rounded-b-[30px]"
          style={{ background: 'linear-gradient(to left, #572D9E, #875ECB)' }}
        >
          <>
            <div className="flex flex-row  items-center">
              <span>
                <img src={TrustedImage} className="h-[80px] w-[80px]" />
              </span>
              <span className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
                Trusted by 900 oncologists
              </span>
            </div>
            <div className="flex flex-row  items-center mt-[50px]">
              <span>
                <img src={ExpertiseImage} className="h-[80px] w-[13.48rem]" />
              </span>
              <span className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
                Crafted with the insights and expertise of cancer survivors and
                oncologists
              </span>
            </div>
          </>
        </div>
      )}
    </div>
  )
}

export default Welcome
